import PageContainer from "../../../ui/page-container/PageContainer";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Typography,
} from "antd";
import { yupValidator } from "../../../utils/yupSync";
import { forms } from "../../../forms";

import { useSettingsQuery } from "../../../hooks/settings/query/useSettings.query";
import { useMemo } from "react";
import { useUpdateAppInfoMutation } from "../../../hooks/settings/mutation/useUpdateAppInfo.mutation";

interface IFieldType {
  appInformation: TSettingsModel["appInformation"];
}

const AppInformation = () => {
  const [form] = Form.useForm<IFieldType>();
  const yupSync = yupValidator(
    forms.appInformation.validation,
    form.getFieldsValue
  );

  const { isLoading, data } = useSettingsQuery();

  const settings: TSettingsModel = useMemo(() => {
    if (!isLoading && data) {
      return data.data;
    } else {
      return undefined;
    }
  }, [isLoading, data]);

  const { isPending, mutateAsync } = useUpdateAppInfoMutation();

  const onFinish = async (values: IFieldType) => {
    const res = await mutateAsync(values);
    if (res.status === "success") {
      message.success(res.message);
    } else {
      message.error(res.data.message);
    }
  };

  return (
    <PageContainer loading={isLoading}>
      <Typography.Title level={4}>App Instructions</Typography.Title>
      <Form
        style={{ marginTop: 20 }}
        layout="vertical"
        name="app-information"
        size="middle"
        form={form}
        onFinish={onFinish}
        initialValues={{
          ...forms.appInformation.values,
          appInformation: settings?.appInformation,
        }}
      >
        <Row gutter={10}>
          <Col span={24}>
            <Typography.Title level={5}>
              Discounted Tokens Instructions
            </Typography.Title>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item<IFieldType>
              name={["appInformation", "discountedToken", "title"]}
              rules={[yupSync]}
              label="Title"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item<IFieldType>
              name={["appInformation", "discountedToken", "message"]}
              rules={[yupSync]}
              label="Descriptions"
            >
              <Input.TextArea rows={5} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <Typography.Title level={5}>Conversion Alert</Typography.Title>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item<IFieldType>
              name={["appInformation", "conversionAlert", "title"]}
              rules={[yupSync]}
              label="Title"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item<IFieldType>
              name={["appInformation", "conversionAlert", "message"]}
              rules={[yupSync]}
              label="Descriptions"
            >
              <Input.TextArea rows={5} />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Form.Item>
          <Button
            loading={isPending}
            size="large"
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </PageContainer>
  );
};

export default AppInformation;
